import PropTypes from 'prop-types';
import AvatarNetwork from '../../../../../common/components/AvatarNetwork';

export const SelectedProfile = ({ profile, onRemove }) => {
  if (!profile) return null;

  return (
    <div className="profile_selected__add_influencer_modal__rm">
      <div className="avatar__add_influencer_modal__rm">
        <AvatarNetwork
          size={40}
          networkSize={16}
          network={profile.network}
          src={profile.picture}
        />
      </div>
      <div>
        <b>{profile.name}</b>
        <span>{profile.username}</span>
      </div>
      <button type="button" onClick={onRemove}>
        Remover
      </button>
    </div>
  );
};

SelectedProfile.propTypes = {
  profile: PropTypes.oneOfType([
    PropTypes.shape({
      username: PropTypes.string.isRequired,
      picture: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      network: PropTypes.string.isRequired,
      counters: PropTypes.shape({
        followers: PropTypes.number.isRequired,
      }),
      engagement_rate: PropTypes.number.isRequired,
    }),
    PropTypes.oneOf([null]),
  ]),
  onRemove: PropTypes.func.isRequired,
};
