import api from '../../../service/api';

export class WorkspaceService {
  static campaigns = {
    create(data) {
      return api.post('/workspace', data);
    },
    update(campaignId, data) {
      return api.put(`/workspace/${campaignId}`, data);
    },
    getAll(page = 0, query = '') {
      return api.get(`/workspace/list/${page}?query=${query}`);
    },
    getById(campaignId) {
      return api.get(`/workspace/${campaignId}`);
    },
    delete(campaignId) {
      return api.delete(`/workspace/${campaignId}`);
    },
    duplicate(campaignId, data) {
      return api.post(`/workspace/${campaignId}/duplicate`, data);
    },
  };

  static columns = {
    create(campaignId, data) {
      return api.post(`/workspace/${campaignId}/column`, data);
    },

    update(campaignId, columnId, data) {
      return api.put(`/workspace/${campaignId}/column/${columnId}`, data);
    },

    delete(campaignId, columnId) {
      return api.delete(`/workspace/${campaignId}/column/${columnId}`);
    },

    changePosition(campaignId, columnId, order) {
      return api.put(`/workspace/${campaignId}/column/${columnId}/move`, { order });
    },
  };

  static profiles = {
    add(campaignId, data) {
      return api.post(`/workspace/${campaignId}/profile`, data);
    },

    delete(campaignId, profileId) {
      return api.delete(`/workspace/${campaignId}/profile/${profileId}`);
    },

    changePosition(campaignId, profileId, data) {
      return api.put(`/workspace/${campaignId}/profile/${profileId}/move`, data);
    },

    update(campaignId, profileId, data) {
      return api.put(`/workspace/${campaignId}/profile/${profileId}`, data);
    },

    search(data) {
      return api.post('/profile/search', {
        items_per_page: 16,
        page: data.page,
        query: data.query,
      });
    },
  };

  static posts = {
    list(proifleId, page, itemsPerPage) {
      return api.post('/profile/posts', {
        profile_id: proifleId,
        page,
        items_per_page: itemsPerPage,
      });
    },
  };

  static demographics = {
    get(profileId, username, network) {
      return api.get(`/demographics/${username}/${profileId}/${network}`);
    },
  };

  static report = {
    profileData(campaignId) {
      return api.get(`/workspace/${campaignId}/report/profiles`);
    },
    postsData(campaignId, page = 0, postTypes = ['story'] ) {
      return api.post(`/workspace/${campaignId}/report/posts`, {page, post_types: postTypes });
    },
    togglePostData(campaignId, metricsPostId, metricsProfileId ) {
      return api.post(`/workspace/${campaignId}/post/toggle` , { metrics_post_id: metricsPostId, metrics_profile_id: metricsProfileId }  );
    },
    downloadExcel(campaignId) {
      return api.get(`/workspace/${campaignId}/report/xls`, {
        responseType: 'blob',
      });
    },
    getPostsDataByProfile(campaignId, metricsProfileId, page = 0, postType = 'all') { 
      return api.get(`/workspace/${campaignId}/report/${metricsProfileId}/posts?page=${page}&post_type=${postType}`);
    }
  };

  static chats = {
      listAllProfilesToChat(campaignId) {
        return api.get(`/workspace/${campaignId}/influencers-to-chat/`);
      },
      listAllUserChannels() {
        return api.get(`/user-channels/`);
      },
      createChat(campaignId, influencerId, influencerChannelAddress) {
        return api.post(`/workspace/${campaignId}/chat`, {
          metrics_profile_id: influencerId,
          profile_channel_address: influencerChannelAddress
        });
      },
      listAllChats(campaignId) {
        return api.get(`/workspace/${campaignId}/chats`);
      },
      sendMessage(chatUid ,message) {
        return api.post(`/workspace/${chatUid}/message`, {
          message: message,
          chat_uid: chatUid
        });
      },
      listAllMessages(chatUid) {
        return api.get(`/workspace/${chatUid}/message`);
      }
  }
}