import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import { Profile } from './Profile';
import Loading from '../../../../../common/components/Loading';

export const ProfileList = forwardRef(({ profiles = [], onSelectProfile, isLoading }, ref) => {
  return (
    <ul
      className="profiles__add_influencer_modal__rm"
      style={{ paddingRight: profiles.length > 4 ? '2px' : '0' }}
    >
      {profiles.map((profile, index) => (
        <Profile
          key={`${profile.username}-${index}`}
          profile={profile}
          ref={profiles.length === index + 1 ? ref : null}
          onSelectProfile={onSelectProfile}
        />
      ))}

      {isLoading && (
        <div>
          <Loading />
        </div>
      )}
    </ul>
  );
});

ProfileList.displayName = 'ProfileList';

ProfileList.propTypes = {
  profiles: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSelectProfile: PropTypes.func.isRequired,
  ref: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(HTMLLIElement) }),
  ]),
};
