import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import Radio from '../../../common/components/Radio';
import Input from '../../../common/components/Input';
import Button from '../../../common/components/Button';
import Spinner from '../../../common/components/Spinner';

const InfluencerContact = ({ onCompleted, selectedChat, isLoading }) => {
  const [radioValue, setRadioValue] = useState(null);
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [touched, setTouched] = useState(false);

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  let options = selectedChat.data.biography_data.emails;

  if (selectedChat.data.biography_data.email) {
    options = [...options, selectedChat.data.biography_data.email];
  }
  options = [...new Set([...options, 'Outro email'])].filter(Boolean);

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    setIsEmailValid(emailRegex.test(emailValue));
  };

  const handleBlur = () => {
    setTouched(true);
    setIsEmailValid(emailRegex.test(email));
  };

  return (
    <div className="influencer-contact">
      <div className="influencer-contact-box">
        {options.length > 1 && (
          <>
            <p>
              Escolha uma opções de e-mail, abaixo, para iniciar uma conversa com o influenciador:
            </p>
            <Radio
              value={radioValue}
              options={options.map((item) => ({ label: item, value: item }))}
              onChange={(value) => {
                setRadioValue(value);
                if (value.includes('Outro')) {
                  setEmail('');
                } else {
                  setEmail(value);
                }
              }}
            />
          </>
        )}

        {options.length === 1 && (
          <p>
            Digite o e-mail do influenciador abaixo para iniciar uma conversa:
          </p>
        )}

        {(options.length === 1 || (radioValue && radioValue.includes('Outro'))) && (
          <Input
            placeholder="exemplo@gmail.com"
            value={email}
            onChange={handleEmailChange}
            onBlur={handleBlur}
          />
        )}

        {touched && !isEmailValid && (
          <p className="error-message" style={{ color: 'red' }}>
            Por favor, insira um email válido.
          </p>
        )}
        <br />
        <Button
          className="create-chat"
          isDisabled={!email || !isEmailValid || isLoading}
          onClick={() => onCompleted(email)}
        >
          {isLoading && <Spinner size={18} />}
          Salvar
        </Button>
      </div>
    </div>
  );
};

InfluencerContact.propTypes = {
  onCompleted: PropTypes.func.isRequired,
  selectedChat: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default InfluencerContact;
