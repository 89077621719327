import React, { useEffect, useState } from 'react';
import { TextArea } from '../../../common/components/TextArea';
import PropTypes from 'prop-types';
import Input from '../../../common/components/Input';
import { Divider } from 'antd';
import Button from '../../../common/components/Button';
import { NewChat } from './NewChat';
import { useCampaign } from '../Campaign/contexts/CampaignProvider';
import AvatarNetwork from '../../../common/components/AvatarNetwork';
import InfluencerContact from './InfluencerContact';
import { WorkspaceService as Service } from '../Campaign/service';
import feedback from '../../../common/components/Feedback';
import StateStatus from '../../../utils/status';
import Loading from '../../../common/components/Loading';

import './styles.scss';

const MessageBalloon = ({ message, timestamp, isCurrentUser }) => {
  // Function to convert text links to clickable links
  const convertLinksToAnchors = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.split(urlRegex).map((part, index) =>
      urlRegex.test(part) ? (
        <a key={index} href={part} target="_blank" rel="noopener noreferrer">
          {part}
        </a>
      ) : (
        part
      )
    );
  };

  return (
    <div className={`message-balloon ${isCurrentUser ? 'current-user' : 'other-user'}`}>
      <div className="message-content">{convertLinksToAnchors(message)}</div>
      <div className="message-info">
        <span className="timestamp">Enviado: {timestamp}</span>
      </div>
    </div>
  );
};

MessageBalloon.propTypes = {
  message: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
  isCurrentUser: PropTypes.bool.isRequired,
};

const Messages = () => {
  const [isNewChatOpen, setIsNewChatOpen] = useState(false);
  const { campaignId } = useCampaign();

  const [conversations, setConversations] = useState({
    status: StateStatus.idle,
    items: [],
  });
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [selectedConversationUid, setSelectedConversationUid] = useState(null);
  const [isSendingMsg, setIsSendingMsg] = useState(false);
  const [isCreatingChat, setIsCreatingChat] = useState(false);
  const [searchContact, setSearchContact] = useState('');

  const [msgs, setMsgs] = useState({
    status: StateStatus.idle,
    items: [],
    restricted: false,
    restrictedMessage: '',
  });

  const onNewChat = (influencer) => {
    influencer.can_see_messages = true;
    influencer.channel = {
      identifier: null,
    };
    setIsNewChatOpen(false);
    setConversations((old) => ({
      ...old,
      items: [influencer, ...old.items],
    }));
    setSelectedConversation(influencer);
    setMsgs({ status: StateStatus.idle, items: [], restricted: false, restrictedMessage: '' });
  };

  const onCreateChat = (influencerEmail) => {
    setIsCreatingChat(true);
    Service.chats
      .createChat(
        campaignId,
        selectedConversation.data.id,
        influencerEmail,
      )
      .then((response) => {
        feedback.success('Conversa criada com o influenciador.');
        setSelectedConversation((old) => ({
          ...old,
          uid: response.data.uid,
          profile_channel_address: influencerEmail,
        }));
        handleListConversations()
      })
      .catch(() => {
        feedback.error('Falha ao criar conversa com o influenciador.');
      })
      .finally(() => setIsCreatingChat(false));
  };

  useEffect(() => {
    if (selectedConversation && selectedConversation.uid) {
      setSelectedConversationUid(selectedConversation.uid);
      setMsgs({ status: StateStatus.idle, items: [], restricted: false, restrictedMessage: '' });
    } else {
      setSelectedConversationUid(null);
      setMsgs({ status: StateStatus.idle, items: [], restricted: false, restrictedMessage: '' });
    }
  }, [selectedConversation]);

  const handleListConversations = () => {
    setConversations((old) => ({ ...old, status: StateStatus.loading }));
    Service.chats
      .listAllChats(campaignId)
      .then((response) => {
        setConversations({
          status: StateStatus.succeeded,
          items: response.data.items,
        });
      })
      .catch(() => {
        feedback.error('Falha ao carregar conversas.');
        setConversations((old) => ({ ...old, status: StateStatus.failed }));
      });
  }

  useEffect(() => {
   handleListConversations()
  }, [campaignId]);

  const handleMessageSend = (message, callback) => {
    setIsSendingMsg(true);
    Service.chats
      .sendMessage(selectedConversation.uid, message)
      .then((response) => {
        setMsgs((old) => ({ ...old, items: [...old.items, response.data] }));
        feedback.success('Mensagem enviada com sucesso.');
        callback();
      })
      .catch(() => {
        feedback.error('Falha ao enviar a mensagem.');
      })
      .finally(() => setIsSendingMsg(false));
  };

  useEffect(() => {
    if (!selectedConversationUid) return;
    setMsgs((old) => ({ ...old, status: StateStatus.loading }));

    Service.chats
      .listAllMessages(selectedConversationUid)
      .then((response) => {
        if (response.data.restricted) {
          setMsgs({
            status: StateStatus.succeeded,
            items: [],
            restricted: true,
            restrictedMessage: response.data.message,
          });
        } else {
          setMsgs({
            status: StateStatus.succeeded,
            items: response.data.items,
            restricted: false,
            restrictedMessage: '',
          });
        }
      })
      .catch(() => {
        feedback.error('Falha ao carregar as mensagens.');
        setMsgs((old) => ({ ...old, status: StateStatus.failed }));
      });
  }, [selectedConversationUid]);

  const handleConversationClick = (conversation) => {
    setSelectedConversation(conversation);
  };

  const filterContacts = (contact) => {
    if (searchContact.length === 0) return true;

    return (
      contact.data.name.toLowerCase().includes(searchContact.toLowerCase()) ||
      (contact.profile_channel_address &&
        contact.profile_channel_address.toLowerCase().includes(searchContact.toLowerCase())) ||
      contact.data.username.toLowerCase().includes(searchContact.toLowerCase())
    );
  };

  return (
    <div className="messages-container">
      {isNewChatOpen && (
        <NewChat
          campaignId={campaignId}
          isOpen={isNewChatOpen}
          onClose={() => setIsNewChatOpen(false)}
          onFinish={onNewChat}
        />
      )}
      <div className="conversations-list">
        <div className="conversations-title">
          <i className="icon icon-messages" /> Mensagens
        </div>

        <div className="conversations-header">
          <Input prefix={<i className="icon icon-search"></i>} onChange={(event) => setSearchContact(event.target.value)} />
          <Divider style={{ margin: '10px' }} />
          <Button onClick={() => setIsNewChatOpen(true)}>
            <i className="icon icon-plus" />
            Iniciar nova conversa
          </Button>
        </div>

        {conversations.items
          .sort((a, b) => b.unread_messages - a.unread_messages)
          .filter(filterContacts)
          .map((conversation) => (
            <div
              key={conversation.id}
              className={`conversation-item ${selectedConversation?.uid === conversation.uid ? 'selected' : ''}`}
              onClick={() => handleConversationClick(conversation)}
            >
              <AvatarNetwork
                size={40}
                networkSize={16}
                network={conversation.data.network}
                src={conversation.data.picture}
              />
              <div className="conversation-info">
                <div className="contact-name">
                  <div>
                    {conversation.data.name} |{' '}
                    <span style={{ fontWeight: 400 }}>{conversation.column.title}</span>
                  </div>{' '}
                  <i style={{ color: conversation.unread_messages > 0 ? '#ED276A' : null }} className="icon icon-ballon" />
                </div>
                <span>@{conversation.data.username}</span>
              </div>
            </div>
          ))}
        {conversations.status === StateStatus.loading && <Loading />}
      </div>
      <div className="message-area">
        {selectedConversation ? (
          <>
            <div className="message-header">
              <div className="contact-data">
                <AvatarNetwork
                  size={40}
                  networkSize={16}
                  network={selectedConversation.data.network}
                  src={selectedConversation.data.picture}
                />
                <div className="contact-name">
                  <div>{selectedConversation.data.name}</div>
                  <span>
                    <i className="icon icon-email-check" />
                    {selectedConversation.profile_channel_address ||
                      'Escolhe o e-mail do influenciador.'}
                  </span>
                </div>
              </div>
            </div>
            <div className="message-list">
              {!selectedConversation.profile_channel_address && selectedConversation && (
                <InfluencerContact
                  isLoading={isCreatingChat}
                  selectedChat={selectedConversation}
                  onCompleted={onCreateChat}
                />
              )}
              {msgs.restricted ? (
                <div className="restricted-message">
                  {msgs.restrictedMessage}
                </div>
              ) : (
                <>
                  {msgs.items.length > 0 && (
                    <div className="msg-scroll">
                      {msgs.items.map((msg) => (
                        <MessageBalloon
                          key={msg.external_id}
                          message={msg.content}
                          timestamp={msg.sent_at}
                          isCurrentUser={msg.direction === 'inbound'}
                        />
                      ))}
                    </div>
                  )}
                  {selectedConversationUid &&
                    msgs.status === StateStatus.succeeded &&
                    msgs.items.length === 0 && (
                      <div className="no-conversation-selected">
                        <p>Você ainda não enviou mensagens para {selectedConversation.data.name}.</p>
                      </div>
                    )}
                  {msgs.status === StateStatus.loading && <Loading />}
                </>
              )}
            </div>
            {/* Only allow sending messages if the chat belongs to the user */}
            {!msgs.restricted && selectedConversation.uid && (
              <TextArea
                isLoading={isSendingMsg}
                onSend={handleMessageSend}
                placeholder="Escrever uma mensagem..."
              />
            )}
          </>
        ) : (
          <div className="no-conversation-selected">
            <p>Selecione uma conversa para iniciar.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Messages;
