import { ProfileList } from '../Campaign/components/AddInfluencerModal/ProfileList';
import { SelectedProfile } from '../Campaign/components/AddInfluencerModal/SelectedProfile';
import { useEffect, useState } from 'react';
import StateStatus from '../../../utils/status';
import { WorkspaceService as Service } from '../Campaign/service';
import feedback from '../../../common/components/Feedback';
import PropTypes from 'prop-types';
import Button from '../../../common/components/Button';
import Modal from '../../../common/components/Modal';
import Loading from '../../../common/components/Loading';

import './styles.scss';

export const NewChat = ({ campaignId, isOpen, onClose, onFinish }) => {
  
  const [influencerState, setInfluencerState] = useState({
    status: StateStatus.idle,
    items: [],
  });

  const [selectedInfluencer, setSelectedInfluencer] = useState(null);

  useEffect(() => {
    setInfluencerState((old) => ({ ...old, status: StateStatus.loading }));
    Service.chats
      .listAllProfilesToChat(campaignId)
      .then((response) => {
        setInfluencerState((old) => ({
          ...old,
          status: StateStatus.succeeded,
          items: response.data.items,
        }));
      })
      .catch(() => {
        feedback.error(
          'Falha ao carregar perfis para iniciar conversa, tente novamente mais tarde.'
        );
        setInfluencerState((old) => ({ ...old, status: StateStatus.failed }));
      });
  }, [campaignId]);



  return (
    <Modal
      title={
        <span style={{ fontWeight: 800, fontSize: '16px' }}>
          Iniciar conversa com influenciador
        </span>
      }
      subtitle="Todos os influenciadores desta Área de Trabalho."
      isVisible={isOpen}
      onClose={onClose}
      className="add_influencer_modal__rm"
    >
      {selectedInfluencer !== null && (
        <SelectedProfile
          profile={influencerState.items[selectedInfluencer].data}
          onRemove={() => setSelectedInfluencer(null)}
        />
      )}

      {influencerState.items && selectedInfluencer === null && (
        <ProfileList
          profiles={influencerState.items.map((item, index) => ({ ...item.data, index }))}
          onSelectProfile={(data) => setSelectedInfluencer(data.index)}
        />
      )}

      {influencerState.status === StateStatus.loading && <Loading />}

      {influencerState.status !== StateStatus.loading && influencerState.items.length === 0 && (
        <div className="no-results__add_influencer_modal__rm">
          <p>Você ainda não incluiu nenhum perfil na sua Área de Trabalho.</p>
        </div>
      )}

      {selectedInfluencer !== null && (
        <div className="buttons__add_influencer_modal__rm">
          <Button variant="outline" type="button" onClick={onClose}>
            Cancelar
          </Button>
          <Button
            onClick={() => onFinish(influencerState.items[selectedInfluencer])}
            type="button"
          >
            Adicionar
          </Button>
        </div>
      )}
    </Modal>
  );
};

NewChat.propTypes = {
  campaignId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
};
