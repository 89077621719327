import { ChevronDown } from 'lucide-react';
import { formatDate } from '../../../../../utils/formatters';
import { useWorkspaces } from '../../contexts/WorkspacesProvider';
import { TableActions } from '../TableActions';
import './styles.scss';

const GET_CAMPAIGN_STATUS = {
  enabled: {
    title: 'Ativo',
    color: '#90E59D',
  },
  no_influencers: {
    title: 'Não há influenciadores em campanha',
    color: '#FFE088',
  },
  disabled: {
    title: 'Inativo',
    color: '#C8C8C8',
  },
  waiting: {
    title: 'Aguardando',
    color: '#B6E8FF',
  },
  missing_hashtags_or_at: {
    title: 'Marcações ou hashtags ausentes',
    color: '#FFB3AA',
  },
};

export const Table = () => {
  const { workspaces } = useWorkspaces();

  const handleOpenCampaign = (campaignId) => {
    window.open(`/#/workspaces/${campaignId}`, '_self');
  };

  const handleOpenMessages = (campaignId) => {
    window.open(`/#/workspaces/${campaignId}?tab=messages`, '_self');
  };

  return (
    <div className="table__container__workspaces">
      <table>
        <thead>
          <tr>
            <th>Área de Trabalho</th>
            <th>
              <button>
                Status <ChevronDown />
              </button>
            </th>
            <th>
              <button>
                Período <ChevronDown />
              </button>
            </th>
            <th>
              <button>Central de Mensagens</button>
            </th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {workspaces?.items?.map((campaign) => {
            const status = GET_CAMPAIGN_STATUS[campaign?.status ?? 'enabled'];

            return (
              <tr key={campaign.uid}>
                <td>
                  <div onClick={() => handleOpenCampaign(campaign.uid)}>{campaign.title}</div>
                </td>
                <td>
                  <div>
                    <span style={{ background: status.color }}>{status.title}</span>
                  </div>
                </td>
                <td>
                  {formatDate(campaign.start_date, 'DD/MM')} -&nbsp;
                  {formatDate(campaign.end_date, 'DD/MM')}
                </td>
                <td>
                  <div className="conversations-title table-messages">
                    <i
                      onClick={() => handleOpenMessages(campaign.uid)}
                      className="icon icon-messages"
                    />
                  </div>
                </td>
                <td>
                  <TableActions campaign={campaign} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
